import img1 from './assets/imbiss-1.png';
import img2 from './assets/imbiss-2.png';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={img1} className="App-logo" alt="logo" />
        <img src={img2} className="App-logo" alt="logo" />

        
   
      </header>
    </div>
  );
}

export default App;
